import { IIndividualAuthenticatedDTO } from '../../../lib/individual';
import {
  IIntegrantTypeDTO,
  ICreateIntegrantTypeDTO,
  IUpdateProductDisableStatusAPIResDTO,
  ICommonResponseDTO
} from '../../../lib/integrantType';
import { useEffect, useRef } from 'react';
import { formDataConfig, url, requestDataConfig, formDataConfigType, formAxiosHeader } from '../../config';
import { generateClientID } from '../../utils';
import { useOrganizationStore } from '../useOrganizationStore';
import { useParams } from 'react-router-dom';
import axiosInstance from './../../utils/api-interceptor';
import { IAPIResponseDTO, IEmptyObjectDTO } from '../../../lib/common';

export interface IFacetDTO {
  id: string;
  isOld: boolean;
  readonly title: string;
  readonly description: string;
}

interface IUseBatchParams {
  integrantTypeId: string;
}

interface IIntegrantTypesService {
  postCSV: (file: File) => Promise<IIntegrantTypeDTO>;
  getAll: (searchValue?: string) => Promise<IIntegrantTypeDTO[] | undefined>;
  getIntegrantData: () => Promise<IIntegrantTypeDTO[] | undefined>;
  updateProductDisabledStatus: (
    integrant_type_id: string,
    is_disabled: boolean
  ) => Promise<IUpdateProductDisableStatusAPIResDTO>;
  getIngredientsByIntegrantTypeId: (integrant_type_id: string) => Promise<ICommonResponseDTO>;
  post: (
    file: File | null | string,
    integrantType: ICreateIntegrantTypeDTO,
    isUpdate: boolean
  ) => Promise<IIntegrantTypeDTO>;
  getSurveyList: () => Promise<IAPIResponseDTO>;
  getSurveyQuestionsList: (integrant_type_id: string) => Promise<IAPIResponseDTO>;
  getSurveyInvitationList: (integrant_type_id: string) => Promise<IAPIResponseDTO>;
  getBunnyVideoUploadInfoAPI: (data: any) => Promise<IAPIResponseDTO>;
  onSaveNIHProductAndIngredients: (data: any) => Promise<IAPIResponseDTO>;
  graphData: (data: { type: string; startDate: unknown; endDate: unknown }) => Promise<any>;
  graphSuccessfulUploadData: (data: { type: string; startDate: unknown; endDate: unknown }) => Promise<any>;
}

const useIntegrantTypesService = (individual: IIndividualAuthenticatedDTO): IIntegrantTypesService => {
  const { selectedOrganization } = useOrganizationStore();
  const params: any = useParams();
  const { integrantTypeId = '' } = useParams<IUseBatchParams>();

  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;
  });

  const getAll: IIntegrantTypesService['getAll'] = async (searchValue?: string) => {
    const { batchId } = params;
    const formData = {
      offset: 0,
      limit: null,
      searchText: searchValue ? searchValue : '',
      organization_id: selectedOrganization.id,
      show_batch: typeof batchId === 'string'
    };

    const integrantTypes = await fetch(
      `${url}integrant/search-products`,
      formDataConfigType(JSON.stringify(formData), individual.access_token)
    ).then(async response => await response.json());

    if (typeof integrantTypes !== 'undefined' && Array.isArray(integrantTypes)) {
      return integrantTypes.filter((intTypes: IIntegrantTypeDTO) => intTypes.organization_id !== null);
    }
  };

  const graphData = async (data: { type: string; startDate: unknown; endDate: unknown }) => {
    const graphData = await axiosInstance.post(
      '/document-metrics/document-metrics-count',
      { organization_id: selectedOrganization.id, type: data.type, start_date: data.startDate, end_date: data.endDate },
      formAxiosHeader(individual.access_token)
    );
    return graphData;
  };

  const graphSuccessfulUploadData = async (data: { type: string; startDate: unknown; endDate: unknown }) => {
    const graphData = await axiosInstance.post(
      '/document-hash/document-added-count',
      { organization_id: selectedOrganization.id, type: data.type, start_date: data.startDate, end_date: data.endDate },
      formAxiosHeader(individual.access_token)
    );
    return graphData;
  };
  const getIntegrantData: IIntegrantTypesService['getIntegrantData'] = async () => {
    if (integrantTypeId) {
      const integrantTypesData = await fetch(
        `${url}integrant-type/${integrantTypeId}`,
        requestDataConfig(individual.access_token)
      ).then(async response => await response.json());

      if (typeof integrantTypesData === 'object') {
        const { facets, ...rest } = integrantTypesData;
        let facetsBeauty = [];
        let facetsAddId = [];
        if (facets) {
          facetsBeauty = JSON.parse(facets);
          facetsAddId = facetsBeauty.filter((item: IFacetDTO) => {
            item.id = generateClientID();
            item.isOld = true;
            return item;
          });
        }
        const updateIntegrantTypesData = rest;
        updateIntegrantTypesData.facets = facetsAddId;
        return updateIntegrantTypesData;
      }
    }
  };

  const postCSV: IIntegrantTypesService['postCSV'] = async file => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(`data-file`, formDataConfig(formData, individual.access_token));
    return response.json();
  };

  const post: IIntegrantTypesService['post'] = async (
    file,
    {
      title,
      description,
      organization_id,
      external_id,
      facets,
      created_by,
      updated_by,
      directions,
      warnings,
      product_type,
      youtube_video_url,
      bunny_video_title
    },
    isUpdate
  ) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image_url', '');
    formData.append('fileName', file ? file : '');
    formData.append('directions', directions);
    formData.append('warnings', warnings);
    formData.append('facets', JSON.stringify(facets));
    formData.append('external_id', external_id);
    formData.append('organization_id', organization_id);
    formData.append('created_by', created_by);
    formData.append('updated_by', updated_by);
    formData.append('product_type', product_type);
    formData.append('youtube_video_url', youtube_video_url);
    if (isUpdate) {
      formData.append('bunny_video_title', bunny_video_title?.trim());
      formData.append('integrant_type_id', integrantTypeId);
    }
    return await axiosInstance
      .post(
        isUpdate ? 'integrant-type/update' : 'integrant-type/create',
        formData,
        formAxiosHeader(individual.access_token)
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  };

  const onSaveNIHProductAndIngredients: IIntegrantTypesService['onSaveNIHProductAndIngredients'] = async (
    data
  ): Promise<IAPIResponseDTO> => {
    return await axiosInstance.post(`integrant-type/import-from-nih`, data, formAxiosHeader(individual.access_token));
  };

  const updateProductDisabledStatus: IIntegrantTypesService['updateProductDisabledStatus'] = async (
    integrant_type_id,
    is_disabled
  ): Promise<any> => {
    return await axiosInstance.post(
      'integrant-type/update-disable-status',
      { integrant_type_id, is_disabled },
      formAxiosHeader(individual.access_token)
    );
  };

  const getIngredientsByIntegrantTypeId: IIntegrantTypesService['getIngredientsByIntegrantTypeId'] = async (
    integrant_type_id
  ): Promise<any> => {
    return await axiosInstance.post(
      'integrant-type/get-ingredients',
      { integrant_type_id },
      formAxiosHeader(individual.access_token)
    );
  };

  const getSurveyList: IIntegrantTypesService['getSurveyList'] = async (): Promise<any> => {
    let params: IEmptyObjectDTO = {};
    if (selectedOrganization?.id) {
      params['organization_id'] = selectedOrganization.id;
    }
    return await axiosInstance.post('integrant-type/get-surveys', params, formAxiosHeader(individual.access_token));
  };

  const getSurveyQuestionsList: IIntegrantTypesService['getSurveyQuestionsList'] = async (
    integrant_type_id
  ): Promise<any> => {
    return await axiosInstance.post(
      'surveyquestions/lists',
      { integrant_type_id },
      formAxiosHeader(individual.access_token)
    );
  };

  const getSurveyInvitationList: IIntegrantTypesService['getSurveyInvitationList'] = async (
    integrant_type_id
  ): Promise<any> => {
    return await axiosInstance.post(
      'survey-invitations/get-all',
      { integrant_type_id },
      formAxiosHeader(individual.access_token)
    );
  };

  const getBunnyVideoUploadInfoAPI: IIntegrantTypesService['getBunnyVideoUploadInfoAPI'] = async (
    data: any
  ): Promise<any> => {
    return await axiosInstance.post(
      'integrant-type/bunny-tus-upload-info',
      data,
      formAxiosHeader(individual.access_token)
    );
  };

  return {
    postCSV,
    getAll,
    post,
    getIntegrantData,
    updateProductDisabledStatus,
    getIngredientsByIntegrantTypeId,
    getSurveyList,
    getSurveyQuestionsList,
    getSurveyInvitationList,
    onSaveNIHProductAndIngredients,
    getBunnyVideoUploadInfoAPI,
    graphData,
    graphSuccessfulUploadData
  };
};

export { useIntegrantTypesService };
