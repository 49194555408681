import React, { FunctionComponent } from 'react';
import UseAnimations from 'react-useanimations';
import { Button } from '../common';
import { largeButtonStyle, primaryButtonStyle, primaryColor } from '../styles/default';
import { useCreateIntegrantTypeStore } from '../hooks/useCreateIntegrantTypeStore';
import { useHistory } from 'react-router-dom';

const IntegrantTypeSaveButton: FunctionComponent<{ isVisible: boolean; isUpdate: boolean; type?: string }> = ({
  isVisible = false,
  isUpdate = false,
  type = ''
}) => {
  const history = useHistory();
  const {
    saveButtonMessage,
    isDetailsDisabled,
    isIngredientsDisabled,
    isVideoDisabled,
    onSave,
    isLoading,
    integrantType,
    currentFile,
    onNIHLabelSave
  } = useCreateIntegrantTypeStore();
  const isSaveEnabled = saveButtonMessage === 'Save';
  const handleOnSave = async (): Promise<void> => {
    if (type === 'importNIHProduct') {
      await onNIHLabelSave();
      history.push('/');
    } else {
      if (currentFile !== null) {
        await onSave(typeof currentFile === 'string' ? '' : currentFile, integrantType, isUpdate);
        // history.push('/');
      }
    }
  };

  return isVisible ? (
    <Button
      disabled={currentFile === null || isDetailsDisabled || isIngredientsDisabled || isVideoDisabled}
      onClick={handleOnSave}
      center
      style={{
        ...primaryButtonStyle,
        ...largeButtonStyle,
        width: '100%',
        backgroundColor: '#008F2B'
      }}
    >
      <UseAnimations
        animationKey="loading"
        size={15}
        style={{ color: 'white', marginRight: 10, opacity: isLoading ? 1 : 0 }}
      />
      {isLoading ? `Saving` : saveButtonMessage}
    </Button>
  ) : null;
};

export { IntegrantTypeSaveButton };
