import React, { useEffect, useState } from 'react';
import {
  Box,
  makeStyles,
  Theme,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  createStyles,
  Dialog
} from '@material-ui/core';
import { Layer } from 'common';
import { useIndividualSubscriptionDetailStore } from 'hooks/useIndividualSubscriptionDetailStore';
import MuiLinearProgress from 'common/MuiLinearProgress';
import DocToolJWTGenerator from '../verifier-publisher/DocToolJWTGenerator';
import { abbrNum } from 'utils';
import moment from 'moment';
import { useIntegrantTypesService } from 'hooks/api/useIntegrantTypesService';
import { useAuthStore } from 'hooks/useAuthStore';
import { MuiSelect } from 'common/formComponents';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import LineChart from 'components/LineChart';
import MuiCircularProgress from 'common/MuiCircularProgress';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    dashboardContainer: {
      width: '100%'
    },
    progressBarDiv: {
      margin: '20px 0'
    }
  })
);

interface DocumentChartData {
  label: string;
  total_doc: number;
  no_of_verify_doc: number;
  no_of_unverify_doc: number;
}

export const filterOption = [
  {
    value: 'last_week',
    label: 'Weekly'
  },
  {
    value: 'last_2_week',
    label: 'Last 2 week'
  },
  {
    value: 'last_month',
    label: 'Last month'
  },
  {
    value: 'last_3_month',
    label: 'Last 3 month'
  },
  {
    value: 'custom',
    label: 'Custom'
  }
];
export default function VerifierDashboard() {
  const { dashboardOverview } = useIndividualSubscriptionDetailStore();
  const classes = useStyle();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down(768));
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const { individual } = useAuthStore();
  const { graphData } = useIntegrantTypesService(individual);
  const [graphMonthlyData, setGraphMonthlyData] = useState<DocumentChartData[]>([]);
  const [startDateTotal, setStartDateTotal] = useState(new Date());
  const [endDateTotal, setEndDateTotal] = useState(null);
  const [selectRange, setSelectRange] = useState('');
  const [openDateSelector, setOpenDateSelector] = useState(false);

  const onDateChangeTotal = (dates: any) => {
    const [start, end] = dates;
    setStartDateTotal(start);
    setEndDateTotal(end);
  };

  const fetchData = async (type: string, customStartDate?: any, customEndDate?: any) => {
    try {
      setIsLoading(true);
      let startDate: unknown = null;
      let endDate: unknown = null;
      const currentDate = moment();
      if (type === 'last_week') {
        startDate = currentDate.startOf('week');
        endDate = currentDate.clone().endOf('week');
      } else if (type === 'last_2_week') {
        const startOfCurrentWeek = currentDate.startOf('week');
        startDate = startOfCurrentWeek.clone().subtract(2, 'weeks');
        endDate = startOfCurrentWeek
          .clone()
          .subtract(1, 'week')
          .endOf('week');
      } else if (type === 'last_month') {
        const startOfCurrentMonth = currentDate.startOf('month');
        startDate = startOfCurrentMonth.clone().subtract(1, 'month');
        endDate = startOfCurrentMonth.clone().subtract(1, 'day');
      } else if (type === 'last_3_month') {
        const startOfCurrentMonth = currentDate.startOf('month');
        startDate = startOfCurrentMonth.clone().subtract(3, 'months');
        endDate = startOfCurrentMonth
          .clone()
          .subtract(1, 'month')
          .endOf('month');
      } else {
        startDate = customStartDate;
        endDate = customEndDate ? customEndDate : customStartDate;
      }
      const totalMonthlyData = await graphData({
        type: type,
        startDate: startDate,
        endDate: endDate
      });
      setIsLoading(false);
      setGraphMonthlyData(totalMonthlyData.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleSelectRange = (event: any) => {
    setSelectRange(event.target.value);
    if (event.target.value !== 'custom') {
      fetchData(event.target.value);
    }
    if (event.target.value === 'custom') {
      setOpenDateSelector(true);
    }
  };

  const handleCloseTotal = () => {
    fetchData('custom', startDateTotal, endDateTotal);
    setOpenDateSelector(false);
  };

  useEffect(() => {
    setSelectRange(filterOption[0].value);
    fetchData(filterOption[0].value);
  }, []);
  return (
    <Layer
      direction="column"
      style={{ margin: '0 auto', padding: match ? 20 : '20px 0 0' }}
      width={match ? '100%' : '50%'}
    >
      <Box className={classes.dashboardContainer} display="flex" flexDirection={'column'}>
        <Typography variant="h5">Verifier Dashboard</Typography>
        <Divider />
        {!isLoading ? (
          <>
            <div style={{ display: 'flex', justifyContent: 'end', marginTop: '25px' }}>
              <span>
                <MuiSelect
                  value={selectRange}
                  onChange={event => handleSelectRange(event)}
                  label=""
                  options={filterOption}
                  sx={{ zIndex: 1 }}
                />
                <Dialog
                  // id={id}
                  open={openDateSelector}
                  onClose={handleCloseTotal}
                >
                  <DatePicker
                    selected={startDateTotal}
                    onChange={onDateChangeTotal}
                    startDate={startDateTotal}
                    endDate={endDateTotal}
                    selectsRange
                    inline
                  />
                </Dialog>
              </span>
            </div>
            <LineChart data={graphMonthlyData} />
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px'
            }}
          >
            <MuiCircularProgress size={50} />
          </div>
        )}
        <Box display="flex" flexDirection={'column'} className={classes.progressBarDiv}>
          <Typography variant="h6"> Your current month document verification limit overview</Typography>
          <MuiLinearProgress
            {...{
              loading: dashboardOverview.loading,
              value:
                (parseInt(dashboardOverview?.data?.current_num_monthly_hashes || '0') * 100) /
                parseInt(dashboardOverview?.data?.num_monthly_hashes || '0'),
              label: `${dashboardOverview?.data?.current_num_monthly_hashes || '0'}/${abbrNum(
                dashboardOverview?.data?.num_monthly_hashes || '0'
              )}`
            }}
          />
        </Box>
        <DocToolJWTGenerator />
      </Box>
    </Layer>
  );
}
