import { IFacetDTO, ICreateFacetDTO } from '../../../lib/facet';
import { IExhibitDTO, ICreateExhibitDTO } from '../../../lib/exhibit';
import {
  ICreateIntegrantResponseDTO,
  IIntegrantDetailDTO,
  IIntegrantDTO,
  IUpdateIntegrantDTO,
  ICommonResponseDTO
} from '../../../lib/integrant';
import { ILocationDTO } from '../../../lib/location';
import { IMutationDTO } from '../../../lib/mutation';
import { useCallback } from 'react';
import useFetch, { Req, Res } from 'use-http';
import {
  formDataConfig,
  formDataConfigTypeJson,
  requestDataConfig,
  url,
  updatedUrl,
  formDataConfigType,
  formAxiosHeader
} from '../../config';
import { IIntegrantDelete, IntegrantKind } from '../../types/common';
import {
  convertToIntegrantCreate,
  convertToUpdateFacet,
  convertToUpdateBulkExhibits,
  convertToUpdateBulkFacets,
  defaultFacetAndExhibitValues,
  generateClientID,
  isEmptyString,
  getTimeNumber,
  getCoordinates
} from '../../utils';
import { useOrganizationStore } from '../useOrganizationStore';
import { useAuthStore } from '../useAuthStore';
import { useCreateTransitStore } from '../useCreateTransitStore';
import { useIntegrantGraphStore } from '../useIntegrantGraphStore';
import { useCreateTransferStore } from '../useCreateTransferStore';
import { convertAddressToLatLng } from '../../utils/google-client';
import axiosInstance from './../../utils/api-interceptor';
import { location_ } from './../../common/models';
import { useToastStore } from 'hooks/useToastStore';

interface IIntegrantsService {
  post: (
    integrant: IIntegrantDTO,
    parent_integrant_id: IIntegrantDTO['id'],
    batch_id: IIntegrantDTO['id'],
    kind?: IntegrantKind
  ) => Promise<ICreateIntegrantResponseDTO>;
  clone: (
    integrant_id: IIntegrantDTO['id'],
    tracking_id: IIntegrantDTO['tracking_id']
  ) => Promise<ICreateIntegrantResponseDTO>;
  postCSV: (file: File) => Promise<ICreateIntegrantResponseDTO>;
  get: (integrant_id: IIntegrantDTO['id']) => Promise<any>;
  getDetail: (integrant_id: any) => Promise<any>;
  getLevel: (integrant_id: any) => Promise<any>;
  put: (integrant_id: IIntegrantDTO['id'], integrant: any) => Promise<IIntegrantDetailDTO | undefined>;
  postTransit: (transitData: any) => Promise<any | undefined>;
  editTransit: (transitData: any) => Promise<any | undefined>;
  postTransfer: (transitData: any) => Promise<any | undefined>;
  editTransfer: (transitData: any) => Promise<any | undefined>;
  postExhibit: (integrant_id: any, file: File | null, exhibit: ICreateExhibitDTO, location: any) => Promise<any>;
  postFacet: (currentIntegrant: any, facet: any) => Promise<IFacetDTO>;
  editIntegrant: (payload: any) => Promise<any | undefined>;
  updateFacet: (integrant_id: any, facet: IFacetDTO) => Promise<IFacetDTO>;
  updateExhibit: (integrant_id: IIntegrantDTO['id'], exhibit: IExhibitDTO) => Promise<IExhibitDTO>;
  postMutation: (
    integrant_id: IIntegrantDTO['id'],
    mutation_integrant_id: IIntegrantDTO['id']
  ) => Promise<IMutationDTO>;
  deleteFacet: <F>(
    integrant_id: IIntegrantDTO['id'],
    facet_id: IFacetDTO['id'],
    currentIntegrant: any
  ) => Promise<IFacetDTO>;
  deleteExhibit: (integrant_id: IIntegrantDTO['id'], exhibit_id: IExhibitDTO['id']) => Promise<IExhibitDTO>;
  deleteIntegrant: (integrant_id: IIntegrantDTO['id'], mutation_id: IIntegrantDTO['id']) => Promise<IIntegrantDelete>;
  loading: boolean;
  initialize: (
    selectedIntegrantTypeId: string,
    selectedIntegrantId: string,
    searchValue: string
  ) => Promise<IIntegrantDTO[] | void>;
  updateFacetsBulk: (integrant_id: string, facets: IFacetDTO[]) => Promise<IFacetDTO[]>;
  updateExhibitsBulk: (integrant_id: string, exhibits: IExhibitDTO[]) => Promise<IExhibitDTO[]>;
  updateStaticBatchInformation: (batchData: any) => Promise<any | undefined>;
  checkStaticBatchInformation: (batchData: any) => Promise<any | undefined>;
  error: boolean | Error | Req<IIntegrantDTO[]> | Res<IIntegrantDTO[]> | any;
  deleteAttachment: (fileName: string) => Promise<ICommonResponseDTO>;
}

const useIntegrantsService = (): IIntegrantsService => {
  const integrantsURL = `${updatedUrl}`;
  const { individual } = useAuthStore();
  const { isOpenToast } = useToastStore();
  const { request, response, loading, error } = useFetch<IIntegrantDTO[]>(updatedUrl, {
    headers: {
      Authorization: individual.access_token
    }
  });
  const { integrantKind } = useIntegrantGraphStore();
  const { currentTransit } = useCreateTransitStore();
  const { currentTransfer } = useCreateTransferStore();
  const { selectedOrganization } = useOrganizationStore();
  const { account_id, id } = selectedOrganization;
  const externalPatten = /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g;

  const requestIntegrants = async (
    selectedIntegrantTypeId: string,
    selectedIntegrantId: string,
    searchValue: string
  ): Promise<IIntegrantDTO[] | void> => {
    const formData = {
      offset: 0,
      limit: null,
      searchText: searchValue,
      organization_id: selectedOrganization.id,
      integrant_type_id: selectedIntegrantTypeId
    };

    const integrants = await fetch(
      `${url}integrant/search`,
      formDataConfigType(JSON.stringify(formData), individual.access_token)
    ).then(async response => await response.json());

    // const integrants = await fetch(
    //   `${integrantsURL}/integrant-type/${selectedIntegrantTypeId}`,
    //   requestDataConfig(individual.access_token)
    // );
    return integrants;
  };

  const initialize: IIntegrantsService['initialize'] = async (
    selectedIntegrantTypeId,
    selectedIntegrantId,
    searchValue
  ) => {
    return requestIntegrants(selectedIntegrantTypeId, selectedIntegrantId, searchValue);
  };

  const editTransit: IIntegrantsService['editTransit'] = useCallback(
    async (transitData: any) => {
      const updatedIntegrant = await request.post('/transit/edit', transitData);
      return updatedIntegrant;
    },
    [request]
  );

  const returnLocationWithCoordinates = async (location: ILocationDTO): Promise<ILocationDTO> => {
    const address = `${location.line_1}, ${location.city}, ${location.state} ${location.country} ${location.zip}`;
    const coordinates = await convertAddressToLatLng(address);
    return typeof coordinates !== 'undefined'
      ? {
          ...location,
          coordinates
        }
      : location;
  };

  const post: IIntegrantsService['post'] = useCallback(
    async (integrant, parent_integrant_id, batch_id) => {
      const hasParentId = parent_integrant_id !== '';
      const integrantCreate = convertToIntegrantCreate(
        integrant,
        parent_integrant_id,
        batch_id,
        integrantKind,
        currentTransit,
        currentTransfer
      );
      const updatedIntegrant: any = await request.post(
        hasParentId ? `?parent_id=${parent_integrant_id}` : ``,
        integrantCreate
      );
      return updatedIntegrant;
    },
    [request, currentTransit, integrantKind, currentTransfer]
  );

  const postTransit: IIntegrantsService['postTransit'] = useCallback(
    async (transitData: any) => {
      const updatedIntegrant = await request.post('/transit/create', transitData);
      return updatedIntegrant;
    },
    [request]
  );

  const postTransfer: IIntegrantsService['postTransfer'] = useCallback(
    async (transitData: any) => {
      const updatedIntegrant = await request.post('/transfer/create', transitData);
      return updatedIntegrant;
    },
    [request]
  );
  const editTransfer: IIntegrantsService['editTransfer'] = useCallback(
    async (transitData: any) => {
      const updatedIntegrant = await request.post('/transfer/edit', transitData);
      return updatedIntegrant;
    },
    [request]
  );
  const clone: IIntegrantsService['clone'] = useCallback(
    async (integrant_id, tracking_id) => {
      const integrantClone = {
        integrant_id: integrant_id,
        external_id: tracking_id,
        external_id_slug: tracking_id.replace(externalPatten, '')
      };
      return await axiosInstance
        .post('integrant/clone', integrantClone, formAxiosHeader(individual.access_token))
        .then((res: any) => {
          return res;
        })
        .catch(err => {
          return err;
        });
      // const updatedIntegrant: any = await request.post(`integrant/clone`, integrantClone);
      // return updatedIntegrant;
    },
    [axiosInstance]
  );

  const get: IIntegrantsService['get'] = async (integrant_id): Promise<any> => {
    return await request.get(`/${integrant_id}`);
  };

  const getDetail: IIntegrantsService['getDetail'] = async (integrant_id): Promise<IIntegrantDetailDTO> => {
    const integrantDetailList = await fetch(`${integrantsURL}/integrant/${integrant_id}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${individual.access_token}`
      })
    });

    const editListDetail = await integrantDetailList.json();

    if (editListDetail.statusCode !== 200) {
      //window.location.replace('/');
    }

    const {
      integrantInfo: { facets, external_id },
      sourceLinks,
      exhibitsInfo
    } = editListDetail;
    let facetsAddId = [];
    if (facets !== '') {
      const facetsBeauty = JSON.parse(facets);
      facetsAddId = facetsBeauty.filter((item: any) => {
        //if (item.title !== '') {
        item.id = generateClientID();
        item.isOld = true;
        return item;
        //}
      });
    }
    const updateExhibitsList: any = [];

    if (exhibitsInfo) {
      exhibitsInfo.forEach((item: any) => {
        const { title, exhibitsType } = item;
        exhibitsType.forEach((subitem: any) => {
          subitem.rank = subitem;
          subitem.exhibit_type = title;
          if (subitem.location_id) {
            subitem.location.id = subitem.location_id;
          }
          updateExhibitsList.push(subitem);
        });
      });
    }

    editListDetail.integrantInfo.facets = facetsAddId;
    editListDetail.integrantInfo.exhibits = updateExhibitsList;
    editListDetail.integrantInfo.sourceLinks = sourceLinks;
    editListDetail.integrantInfo.tracking_id = external_id;
    return editListDetail;
  };

  const getLevel: IIntegrantsService['getLevel'] = async (integrant_id): Promise<any> => {
    // const levelDetail = await fetch(
    //   `${url}links/tree`,
    //   formDataConfigTypeJson({ level: '10', integrant_id: integrant_id }, individual.access_token)
    // ).then(async response => await response.json());
    return axiosInstance
      .post('links/tree', { level: '10', integrant_id: integrant_id }, formAxiosHeader(individual.access_token))
      .then((response: any) => {
        if (response.hasOwnProperty('resultArray')) {
          return response.resultArray;
        } else {
          return response;
        }
      })
      .catch(err => {
        return err;
      });
  };

  const put: IIntegrantsService['put'] = useCallback(
    async (integrant_id, integrant) => {
      //eslint-disable-next-line
      const externalPatten = /[@!&\/\\#,+()$~%.'"`_:*?<>{}-]/g;
      const {
        id,
        organization_id,
        integrant_type_id,
        title,
        description,
        tracking_id,
        facets,
        created_by,
        updated_by,
        buy_again_url,
        location,
        exhibits
      } = integrant;
      exhibits &&
        exhibits.filter((item: any) => {
          item.location_id = item.location.id;
          delete item.location;
          return item;
        });
      //let updatedLocObj;
      // if (location) {
      //   const { coordinates, ...locationObj } = location;
      //   updatedLocObj = {
      //     ...locationObj,
      //     coordinates: '[' + coordinates + ']'
      //   };
      // } else {
      //   const { coordinates, ...locationObj } = location_;
      //   updatedLocObj = {
      //     ...locationObj,
      //     coordinates: '[' + coordinates + ']'
      //   };
      // }
      const updatedObj: any = {
        parent_id: integrant_id ? integrant_id : '',
        is_published: false,
        qr_url: '',
        external_id_slug: tracking_id.replace(externalPatten, ''),
        buy_again_url,
        organization_id,
        integrant_type_id,
        title,
        description,
        facets: typeof facets === 'string' ? facets : JSON.stringify(facets),
        created_by: account_id,
        updated_by: account_id,
        //location: updatedLocObj,
        exhibits,
        external_id: tracking_id
        //location_id: location.id
      };
      if (location && location.id) {
        updatedObj.location_id = location.id;
      }
      return await axiosInstance
        .post('integrant/create', updatedObj, formAxiosHeader(individual.access_token))
        .then((res: any) => {
          return res;
        })
        .catch(err => {
          return err;
        });
    },
    [axiosInstance]
  );

  const locationUpdate = async (location: any): Promise<any | undefined> => {
    const { account_id } = selectedOrganization;
    const { id } = location;
    const updatedAddress = await returnLocationWithCoordinates(location);
    const { coordinates, line_1, line_2, zip, city, state, country, description } = updatedAddress;
    const updatedLocationObj = {
      description: description ? description : '',
      id,
      line_1,
      line_2,
      zip,
      city,
      state,
      country,
      coordinates: getCoordinates(coordinates),
      organization_id: selectedOrganization.id,
      created_by: account_id,
      updated_by: account_id
    };
    return await axiosInstance
      .post('location/save', updatedLocationObj, formAxiosHeader(individual.access_token))
      .then((locationResponse: any) => {
        return locationResponse.id;
      })
      .catch(err => {
        return err;
      });
    // const locationResponse: any = await request.post('location/save', updatedLocationObj);
    // return locationResponse.id;
  };

  const postExhibit: IIntegrantsService['postExhibit'] = async (integrant, file, exhibit, location) => {
    const { title, description, exhibit_type, effective_date, testing_lab_id } = exhibit;
    const exhibitObj: any = {
      //location_id: '',
      title,
      description,
      exhibit_type,
      effective_date,
      url: '',
      testing_lab_id
    };
    if (file) {
      const formDataImage = new FormData();
      formDataImage.append('files[]', file);
      formDataImage.append('organization_id', individual.organization_id);
      // const responseImage = await fetch(
      //   `${url}integrant/upload`,
      //   formDataConfig(formDataImage, individual.access_token)
      // ).then(async response => await response.json());

      const responseImage = await axiosInstance
        .post('integrant/upload', formDataImage, formAxiosHeader(individual.access_token))
        .then((res: any) => {
          return res;
        })
        .catch(err => {
          return err;
        });
      if (!responseImage.hasOwnProperty('statusCode')) {
        exhibitObj.url = responseImage[0];
        if (
          location !== null &&
          typeof location === 'object' &&
          !isEmptyString(location.line_1) &&
          !isEmptyString(location.city) &&
          !isEmptyString(location.state) &&
          !isEmptyString(location.zip) &&
          !isEmptyString(location.country)
        ) {
          const locationUpdateData = await locationUpdate(location);
          exhibitObj.location_id = locationUpdateData;
        }
        const updatedObj: any = {
          integrant_id: integrant.id,
          exhibits: [exhibitObj]
        };
        const response = await editIntegrant(updatedObj);
        return responseImage[0];
      }
    } else if (exhibit.url) {
      exhibitObj.url = exhibit.url;
      if (
        location !== null &&
        typeof location === 'object' &&
        !isEmptyString(location.line_1) &&
        !isEmptyString(location.city) &&
        !isEmptyString(location.state) &&
        !isEmptyString(location.zip) &&
        !isEmptyString(location.country)
      ) {
        const locationUpdateData = await locationUpdate(location);
        exhibitObj.location_id = locationUpdateData;
      }
      const updatedObj: any = {
        integrant_id: integrant.id,
        exhibits: [exhibitObj]
      };
      const response = await editIntegrant(updatedObj);
      return exhibit.url;
    }
  };

  const postCSV: IIntegrantsService['postCSV'] = async file => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(`${integrantsURL}/data-file`, formDataConfig(formData, individual.access_token));
    return response.json();
  };

  const postMutation: IIntegrantsService['postMutation'] = async (integrant_id, mutation_integrant_id) => {
    const updatedMutation: any = await request.post(`/${integrant_id}/mutations`, mutation_integrant_id);
    return updatedMutation;
  };

  const postFacet: IIntegrantsService['postFacet'] = async (currentIntegrant, facet) => {
    const updatedObj = {
      integrant_id: currentIntegrant.id,
      facets: ''
    };
    if (currentIntegrant.facets && currentIntegrant.facets.length) {
      updatedObj.facets = JSON.stringify(facet);
    }
    const updatedFacet: any = await request.post(`/integrant/edit`, updatedObj);
    if (updatedFacet.error) {
      isOpenToast('isError', updatedFacet?.message || 'Internal server error please try again!!!');
      return facet;
    } else {
      const {
        integrantInfo: { facets }
      } = updatedFacet;
      let updatefacets = JSON.parse(facets);
      if (typeof updatefacets === 'object') {
        updatefacets.filter((item: any) => {
          item.isOld = true;
          return item;
        });
      }
      return updatefacets;
    }
  };

  const editIntegrant: IIntegrantsService['editIntegrant'] = async payload => {
    const updatedPayload: any = await request.post(`/integrant/edit`, payload);
    if (updatedPayload?.error) {
      isOpenToast('isError', updatedPayload?.message || 'Internal server error please try again!!!');
      return null;
    }
    return updatedPayload;
  };

  const updateStaticBatchInformation: IIntegrantsService['updateStaticBatchInformation'] = async payload => {
    const updatedPayload: any = await request.post(`/integrant/update-static-batch-information`, payload);
    if (updatedPayload?.error) {
      isOpenToast('isError', updatedPayload?.message || 'Internal server error please try again!!!');
      return null;
    }
    return updatedPayload;
  };

  const checkStaticBatchInformation: IIntegrantsService['checkStaticBatchInformation'] = async payload => {
    const updatedPayload: any = await request.post(`/integrant/check-static-batch-information`, payload);
    return updatedPayload;
  };

  const updateFacet: IIntegrantsService['updateFacet'] = async (integrant, facet) => {
    const updatedFacet: any = await request.put(`/${integrant.id}/facets/${facet.id}`, convertToUpdateFacet(facet));
    return updatedFacet;
  };

  const updateFacetsBulk: IIntegrantsService['updateFacetsBulk'] = async (integrant_id, facets) => {
    if (!facets?.length || !!facets?.find((f: IFacetDTO) => !f.title || !f.description)) return facets;
    const updatedFacets: any = await request.post(`/integrant/edit`, {
      integrant_id,
      facets: JSON.stringify(facets)
    });
    if (updatedFacets.error) {
      isOpenToast('isError', updatedFacets?.message || 'Internal server error please try again!!!');
      return facets;
    } else {
      const { integrantInfo } = updatedFacets;
      return JSON.parse(integrantInfo?.facets) || facets;
    }
  };

  const updateExhibit: IIntegrantsService['updateExhibit'] = async (integrant_id, exhibit) => {
    // const updatedExhibit: IExhibitDTO = await request.put(
    //   `/${integrant_id}/exhibits/${exhibit.id}`,
    //   defaultFacetAndExhibitValues(exhibit)
    // );
    return exhibit;
  };

  const updateExhibitsBulk: IIntegrantsService['updateExhibitsBulk'] = async (integrant_id, exhibits) => {
    // const updatedExhibits: IExhibitDTO[] = await request.put(
    //   `/${integrant_id}/exhibits?integrant_id=${integrant_id}`,
    //   convertToUpdateBulkExhibits(exhibits)
    // );
    return exhibits;
  };

  const deleteIntegrant: IIntegrantsService['deleteIntegrant'] = useCallback(
    async (integrant_id, mutation_id) => {
      const updatedIntegrant: any = await request.delete(`/${integrant_id}/mutations/${mutation_id}`);
      return updatedIntegrant;
    },
    [request]
  );

  // const deleteFacet: IIntegrantsService['deleteFacet'] = useCallback(
  //   async (integrant_id: IIntegrantDTO['id'], facet_id: IFacetDTO['id']) => {
  //     const updatedIntegrant: IFacetDTO = await request.delete(`/${integrant_id}/facets/${facet_id}`);
  //     return updatedIntegrant;
  //   },
  //   [request]
  // );

  const deleteFacet: IIntegrantsService['deleteFacet'] = async (
    integrant_id: IIntegrantDTO['id'],
    facet_id: IFacetDTO['id'],
    currentIntegrant: any
  ) => {
    const { facets } = currentIntegrant;
    const updateFacetsList = facets.filter((item: any) => {
      return item.id !== facet_id;
    });
    const payload = {
      integrant_id,
      facets: JSON.stringify(updateFacetsList)
    };
    const updatedPayload: any = await request.post(`/integrant/edit`, payload);
    if (updatedPayload?.error) {
      isOpenToast('isError', updatedPayload?.message || 'Internal server error please try again!!!');
      return null;
    }
    return updatedPayload;
  };

  const deleteExhibit: IIntegrantsService['deleteExhibit'] = useCallback(
    async (integrant_id: IIntegrantDTO['id'], exhibit_id: IExhibitDTO['id']) => {
      const updatedIntegrant: any = await request.delete(`/${integrant_id}/exhibits/${exhibit_id}`);
      return updatedIntegrant;
    },
    [request]
  );

  const deleteAttachment: IIntegrantsService['deleteAttachment'] = useCallback(
    async fileName => {
      const data = {
        file_name: fileName
      };
      return await axiosInstance
        .post('/integrant/delete-attachment', data, formAxiosHeader(individual.access_token))
        .then((res: any) => {
          return res;
        })
        .catch(err => {
          return {
            status: '0',
            message: err.message,
            data: null
          };
        });
    },
    [axiosInstance]
  );

  return {
    post,
    get,
    put,
    postExhibit,
    postFacet,
    clone,
    loading,
    updateFacet,
    postCSV,
    deleteFacet,
    deleteExhibit,
    deleteIntegrant,
    getDetail,
    getLevel,
    postMutation,
    initialize,
    updateFacetsBulk,
    updateExhibitsBulk,
    updateExhibit,
    postTransit,
    editTransit,
    postTransfer,
    editTransfer,
    editIntegrant,
    updateStaticBatchInformation,
    checkStaticBatchInformation,
    error,
    deleteAttachment
  };
};
export { useIntegrantsService };
