import React, { FunctionComponent, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuthStore } from '../hooks/useAuthStore';
import { Login } from '../route/login';
import { Subscription } from '../route/subscription';
import { Register } from '../route/register';
import { Batch } from './batch';
import { Dashboard } from './dashboard';
import { Recover } from './recover';
import { UpdatePassword } from './update-password';
import { Transfer } from './transferred';
import { PreRegistration } from './pre-registration';
import ConnectionPage from './connections';
import { SubscriptionDetails } from './subscription/SubscriptionDetails';
import { UpgradeSubscription } from './subscription/UpgradeSubscription';
import { VerifyOTP } from './verify-otp';
import { UpgradeSuccessfully } from './subscription/UpgradeSuccessfully';
import { Profile } from './profile';
import { OrganizationInformation } from './profile/OrganizationInformation';
import { UserAccounts } from './profile/UserAccounts';
import PageNotFound from './page-not-found';
import ThankYou from './pre-registration/thank-you';
import ProtectedRoute from '../components/ProtectedRoute';
import { AddOrganization } from './../route/add-organization';
import { Widget } from './widget';
import SurveyContainer from './survey';
import CreateSurvey from './survey/CreateSurvey';
import { CSV } from './csv';
import { verifyEnvironment } from '../utils';
import { useRouter } from '../hooks/useRouter';
import { BuyNewSubscription } from './subscription/BuyNewSubscription';
import { BuyNewSubThankYou } from './subscription/BuyNewSubThankYou';
import OrganizationExhibit from './organization-exhibit';
import CreateorEditOrganizationExhibit from './organization-exhibit/CreateOrEditOrganizationExhibit';
import ImportProductsFromNIH from './import-product-from-nih';
import IngredientComparision from './ingredient-comparision';
import DocToolGuide from './doc-tool/DocToolGuide';

//import { Reset } from './reset-password';
let protectedRoute = [
  { as: Login, path: '/login' },
  { as: Subscription, path: '/plans' },
  { as: Subscription, path: '/plans/:token' },
  { as: Recover, path: '/recover' },
  { as: Register, path: '/register' },
  { as: PreRegistration, path: '/registration' },
  { as: PreRegistration, path: '/registration/:plantype' },
  { as: PreRegistration, path: '/registration/:plantype/:planStatus' },
  { as: PageNotFound, path: '/404' },
  { as: ThankYou, path: '/thankyou' },
  { as: ThankYou, path: '/thank-you' },
  { as: AddOrganization, path: '/add-organization' },
  { as: VerifyOTP, path: '/verify-otp' },

  { as: UpgradeSubscription, path: '/plans/upgrade/:productID', isProtected: true },
  { as: BuyNewSubscription, path: '/plans/buyNew/:productID', isProtected: true },
  { as: BuyNewSubThankYou, path: '/buy-new-product-thankyou' },
  { as: UpgradeSuccessfully, path: '/upgrade-successfully', isProtected: true },
  { as: OrganizationInformation, path: '/organization', isProtected: true },
  { as: UserAccounts, path: '/organization/:userType', isProtected: true },
  { as: Batch, path: '/batches/create/:integrantTypeId', isProtected: true },
  { as: SubscriptionDetails, path: '/subscription', isProtected: true },
  { as: UpdatePassword, path: '/update-password', isProtected: true },
  { as: Batch, path: '/batches/:batchId', isProtected: true },
  { as: Transfer, path: '/transferred', isProtected: true },
  { as: Profile, path: '/profile', isProtected: true },
  { as: Dashboard, path: '/survey-questions/:integrantTypeId/:tabIndex', isProtected: true },
  { as: Dashboard, path: '/create-new-product', isProtected: true },
  { as: Dashboard, path: '/update-product/:integrantTypeId', isProtected: true },
  { as: Dashboard, path: '/', isProtected: true },
  { as: Widget, path: '/widget', isProtected: true },
  { as: SurveyContainer, path: '/survey', isProtected: true },
  { as: SurveyContainer, path: '/survey/:integrantTypeId', isProtected: true },
  { as: CreateSurvey, path: '/create-audience/:tabIndex', isProtected: true },
  { as: ConnectionPage, path: '/my-connections', isProtected: true },
  { as: OrganizationExhibit, path: '/organization-exhibits', isProtected: true },
  { as: CreateorEditOrganizationExhibit, path: '/organization-exhibits/:type', isProtected: true },
  { as: CreateorEditOrganizationExhibit, path: '/organization-exhibits/:type/:id', isProtected: true },
  { as: ImportProductsFromNIH, path: '/import-product-from-nih', isProtected: true },
  { as: IngredientComparision, path: '/ingredient-comparision', isProtected: true },
  { as: DocToolGuide, path: '/doc-tool-guide' }
];

if (!verifyEnvironment('live')) {
  protectedRoute = [...protectedRoute, { as: CSV, path: '/csv', isProtected: true }];
}
// if (!verifyEnvironment('live') && !verifyEnvironment('qa')) {
//   protectedRoute = [...protectedRoute];
// }

const Routes: FunctionComponent = () => {
  const { isLoggedIn } = useAuthStore();
  const otpVerified = localStorage.getItem('otpVerified');
  return (
    <Switch>
      {protectedRoute.map((props: any, index: number) => {
        const { isProtected, ...item } = props;
        if (isProtected) {
          return <ProtectedRoute {...item} exact isLoggedIn={isLoggedIn} otpVerified={otpVerified} key={index} />;
        } else {
          const { as: Component, path } = item;
          return <Route key={index} exact path={path} render={() => <Component isLoggedIn={isLoggedIn} />} />;
        }
      })}
    </Switch>
  );
};

export default Routes;
