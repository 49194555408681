import React, { FunctionComponent, useState } from 'react';
import { Input, Layer } from '../common';
import { useCreateBatchStore } from '../hooks/useCreateBatchStore';
import { useIntegrantGraphStore } from '../hooks/useIntegrantGraphStore';
import { useIntegrantPaneStore } from '../hooks/useIntegrantPaneStore';
import { labelStyle } from '../styles/default';
import { isClientId } from '../config';
import { isEmptyString, replaceSubString, validURL } from '../utils';
import { useToastStore } from '../hooks/useToastStore';
import { PrimaryButton } from 'common/Button/buttons';

interface IIntegrantTrackingInputProps {
  integrantTrackingId: string;
  buyAgainUrl: string;
}

const IntegrantTrackingInput: FunctionComponent<IIntegrantTrackingInputProps> = ({
  integrantTrackingId,
  buyAgainUrl
}): JSX.Element => {
  const { isDraft } = useCreateBatchStore();
  const [tracking_id, setTracking] = useState(integrantTrackingId);
  const [isTrackingButton, setisTrackingButton] = useState(false);
  const [isBuyNowButton, setisBuyNowButton] = useState(false);
  const [buy_again_url, setBuyAgainUrl] = useState(buyAgainUrl);

  const [isLoading, setLoading] = useState(false);
  const trackingIdExists = !isEmptyString(integrantTrackingId);
  const { onUpdate, onTrackingUpdate, onBuyNowUpdate, checkCurrentBatch } = useIntegrantPaneStore();
  const {
    currentIntegrant,
    setCurrentIntegrant,
    currentIntegrant: { id, integrantsType }
  } = useIntegrantGraphStore();
  const { isOpenToast } = useToastStore();

  let isDisabled = false;
  if (
    typeof integrantsType === 'object' &&
    integrantsType.current_integrant_id &&
    integrantsType.current_integrant_id === id
  ) {
    isDisabled = true;
  }

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    event.preventDefault();
    setCurrentIntegrant({ ...currentIntegrant, tracking_id });
    if (!isDraft) {
      setLoading(true);
      await onUpdate({ id: currentIntegrant.id, tracking_id });
      setLoading(false);
    }
  };

  const handleSubmitTracking = async (): Promise<void> => {
    if (!isDraft) {
      setLoading(true);
      await onUpdate({ id: currentIntegrant.id, tracking_id });
      setLoading(false);
    }
  };

  const handleSubmitBuyAgainUrl = async (): Promise<void> => {
    if (!isDraft) {
      setLoading(true);
      await onUpdate({ id: currentIntegrant.id, buy_again_url });
      setLoading(false);
    }
  };

  const handleBuyNowSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    setCurrentIntegrant({ ...currentIntegrant, buy_again_url });
    await onBuyNowUpdate({ id: currentIntegrant.id, buy_again_url });
    setLoading(false);
    setisBuyNowButton(false);
  };

  const handleTrackingSubmit = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    const checkbatchdIntegrant: any = await checkCurrentBatch(currentIntegrant, tracking_id);
    if (checkbatchdIntegrant) {
      setLoading(false);
      isOpenToast('isError', 'Static Batch Number Already Exists!');
    } else {
      const updateTracking = await onTrackingUpdate({ id: currentIntegrant.id, tracking_id });
      if (typeof updateTracking === 'object' && updateTracking.integrantInfo) {
        const {
          integrantInfo: { qr_url }
        } = updateTracking;
        setCurrentIntegrant({ ...currentIntegrant, qr_url, tracking_id });
      } else {
        setCurrentIntegrant({ ...currentIntegrant, tracking_id });
      }
      setLoading(false);
      setisTrackingButton(false);
    }
  };

  if (!isClientId(currentIntegrant.id)) {
    return (
      <form>
        <h5 style={labelStyle}>
          Tracking<span className="asterisk-symbol">*</span>
        </h5>
        <Layer>
          <Input
            autoFocus={isDraft}
            value={tracking_id}
            required={isEmptyString(tracking_id)}
            disabled={isDisabled}
            placeholder="Tracking ID"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
              setCurrentIntegrant({
                ...currentIntegrant,
                tracking_id: replaceSubString(evt.currentTarget.value)(' ')('')
              });
              setTracking(replaceSubString(evt.currentTarget.value)(' ')(''));
              if (
                currentIntegrant &&
                currentIntegrant?.external_id &&
                evt?.currentTarget?.value != currentIntegrant?.external_id
              ) {
                setisTrackingButton(true);
              } else {
                setisTrackingButton(false);
              }
            }}
            style={{ width: tracking_id !== '' && isTrackingButton ? 'calc(100% - 110px)' : '100%' }}
          />
          {tracking_id !== '' && isTrackingButton && (
            <PrimaryButton
              children={'Save'}
              icon="save"
              style={{ width: '110px', borderRadius: 0 }}
              secondaryButton={true}
              loading={isLoading}
              onClick={(event: any): any => handleTrackingSubmit(event)}
            />
          )}
        </Layer>

        <h5 style={labelStyle}>
          Buy Again URL<span className="asterisk-symbol">*</span>
        </h5>
        <Layer>
          <Input
            autoFocus={isDraft}
            value={buy_again_url}
            required={validURL(buy_again_url)}
            placeholder="Tracking ID"
            onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
              setCurrentIntegrant({
                ...currentIntegrant,
                buy_again_url: replaceSubString(evt.currentTarget.value)(' ')('')
              });
              setBuyAgainUrl(replaceSubString(evt.currentTarget.value)(' ')(''));
              setisBuyNowButton(true);
            }}
            style={{ width: !validURL(buy_again_url) && isBuyNowButton ? 'calc(100% - 110px)' : '100%' }}
          />
          {!validURL(buy_again_url) && isBuyNowButton && (
            <PrimaryButton
              children={'Save'}
              icon="save"
              style={{ width: '110px', borderRadius: 0 }}
              secondaryButton={true}
              loading={isLoading}
              onClick={(event: any): any => handleBuyNowSubmit(event)}
            />
          )}
        </Layer>
      </form>
    );
  }
  return (
    <form>
      <h5 style={labelStyle}>
        Tracking<span className="asterisk-symbol">*</span>
      </h5>
      <Layer>
        <Input
          autoFocus={isDraft}
          //value={trackingIdExists ? integrantTrackingId : tracking_id}
          value={tracking_id}
          // required={isEmptyString(tracking_id)}
          placeholder="Tracking ID"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
            setCurrentIntegrant({
              ...currentIntegrant,
              tracking_id: replaceSubString(evt.currentTarget.value)(' ')('')
            });
            setTracking(replaceSubString(evt.currentTarget.value)(' ')(''));
            handleSubmitTracking();
          }}
        />
        {/* {!trackingIdExists ? (
          <Button
            style={{ width: 200, ...primaryButtonStyle }}
            onClick={(event): Promise<void> => handleSubmit(event)}
            center
          >
            <GoCheck style={iconStyle} />
            {isLoading ? 'Saving' : 'Save'}
          </Button>
        ) : null} */}
      </Layer>
      <h5 style={labelStyle}>
        Buy Again URL<span className="asterisk-symbol">*</span>
      </h5>
      <Input
        value={buy_again_url}
        // required={validURL(buy_again_url)}
        //disabled={trackingIdExists}
        placeholder="Buy Again URL"
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
          setCurrentIntegrant({
            ...currentIntegrant,
            buy_again_url: replaceSubString(evt.currentTarget.value)(' ')('')
          });
          setBuyAgainUrl(replaceSubString(evt.currentTarget.value)(' ')(''));
          handleSubmitBuyAgainUrl();
        }}
      />
    </form>
  );
};

export { IntegrantTrackingInput };
