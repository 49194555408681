import { useContext, useEffect, useState } from 'react';
import { CreateIntegrantTypeContext, ICreateIntegrantTypeState } from '../context/createIntegrantTypeContext';
import { ICreateIntegrantTypeDTO } from '../../lib/integrantType';
import { IFacetDTO } from '../../lib/facet';
import { isEmptyString, generateClientID, convertToCreateFacets, validURL } from '../utils';
import { titles } from '../config';
import { useIntegrantTypesService } from './api/useIntegrantTypesService';
import { useAuthStore } from './useAuthStore';
import { useIntegrantTypeStore } from './useIntegrantTypeStore';
import { useIntegrantGraphStore } from './useIntegrantGraphStore';
import { useIntegrantPaneStore } from './useIntegrantPaneStore';
import { useToastStore } from '../hooks/useToastStore';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import { create_integrant_type_ } from '../common/models';
import { useRouter } from './useRouter';

interface ICreateIntegrantTypeStore extends ICreateIntegrantTypeState {
  onAddFacet: (facet: IFacetDTO, ingredient?: any) => Promise<void>;
  onRemoveFacet: (facetID: string, ingredient?: any) => Promise<void>;
  onClearData: () => Promise<void>;
  onUpdateDetails: (
    key:
      | 'title'
      | 'description'
      | 'external_id'
      | 'warnings'
      | 'directions'
      | 'product_type'
      | 'youtube_video_url'
      | 'bunny_video_title',
    value: string,
    ingredient?: any
  ) => void;
  gotoSlideIndex: (i: number, isUpdate: boolean, ingredient?: any) => void;
  isDetailsDisabled: boolean;
  isFacetsDisabled: boolean;
  isVideoDisabled: boolean;
  isIngredientsDisabled: boolean;
  saveButtonMessage: string;
  onSave: (file: File | null | string, integrantType: ICreateIntegrantTypeDTO, isUpdate: boolean) => void;
  setOpen: (isOpen: boolean) => void;
  setFile: (file: File | null | string, ingredient?: any) => void;
  setBunnyVideoFile: (file: File | null, ingredient?: any) => void;
  onProductUpdateStoreInitialData: (data: any) => void;
  setOpenSurveyQuestion: (openSurveyQuestion: boolean) => void;
  updateIngredients: (id: number, data: any) => any;
  onRemoveIngredients: (id: number) => any;
  addIngredients: (data: any) => any;
  onNIHLabelSave: () => any;
  verifyIngredientStaticBatchId: (ingredient: any) => void;
  onRemoveBunnyVideo: () => void;
}

const useCreateIntegrantTypeStore = (): ICreateIntegrantTypeStore => {
  const [state, setState] = useContext(CreateIntegrantTypeContext);
  const { individual } = useAuthStore();
  const { post, getIntegrantData, onSaveNIHProductAndIngredients } = useIntegrantTypesService(individual);
  const {
    integrantType,
    isLoading,
    isOpen,
    slideIndex,
    currentFile,
    facets,
    old_external_id,
    openSurveyQuestion,
    ingredients,
    isSubmitForm,
    bunny_video_file,
    createOrUpdateIntegrantTypeData,
    isDeleteBunnyVideo
  } = state;
  const [saveButtonMessage, setSaveButtonMessage] = useState('');
  const { initIntegrantType } = useIntegrantTypeStore();
  const { checkCurrentBatch } = useIntegrantPaneStore();
  const { currentIntegrant } = useIntegrantGraphStore();
  const { isOpenToast } = useToastStore();
  const { navigate } = useRouter();
  const { selectedOrganization } = useOrganizationStore();

  const addIngredients: ICreateIntegrantTypeStore['addIngredients'] = (data: any = []) => {
    data = data?.map((item: any) => ({
      ...create_integrant_type_,
      ...item,
      slideIndex: 0,
      isUniqueBatchId: false,
      isBatchIdVerified: false,
      isSubmitForm: false
    }));
    setState((pre: any) => ({ ...pre, ingredients: [...pre?.ingredients, ...data] }));
  };

  const updateIngredients: ICreateIntegrantTypeStore['updateIngredients'] = (id: number = 0, data: any = {}) => {
    setState((pre: any) => ({
      ...pre,
      ingredients: pre?.ingredients?.map((ingredient: any) => {
        return ingredient?.id === id
          ? {
              ...ingredient,
              ...data
            }
          : ingredient;
      })
    }));
  };

  const onRemoveIngredients: ICreateIntegrantTypeStore['onRemoveIngredients'] = (id: number) => {
    setState((pre: any) => ({
      ...pre,
      ingredients: pre?.ingredients?.filter((ingredient: any) => {
        return ingredient?.id !== id;
      })
    }));
  };

  const verifyIngredientStaticBatchId: ICreateIntegrantTypeStore['verifyIngredientStaticBatchId'] = async (
    ingredient: any = null
  ) => {
    const checkCurrentBatchData: any = await checkCurrentBatch(
      { ...currentIntegrant, organization_id: selectedOrganization.id },
      ingredient.external_id
    );
    if (checkCurrentBatchData) {
      setState((pre: any) => ({
        ...pre,
        ingredients: pre?.ingredients?.map((item: any) =>
          item?.id === ingredient?.id ? { ...item, isUniqueBatchId: false, isBatchIdVerified: true } : item
        )
      }));
    } else {
      setState((pre: any) => ({
        ...pre,
        ingredients: pre?.ingredients?.map((item: any) =>
          item?.id === ingredient?.id ? { ...item, isUniqueBatchId: true, isBatchIdVerified: true } : item
        )
      }));
    }
  };

  const isDetailsDisabled: ICreateIntegrantTypeStore['isDetailsDisabled'] =
    state.currentFile === null ||
    isEmptyString(state.integrantType.description) ||
    isEmptyString(state.integrantType.title) ||
    isEmptyString(state.integrantType.directions) ||
    isEmptyString(state.integrantType.product_type) ||
    typeof state.integrantType.product_type !== 'string';

  const isFacetsDisabled: ICreateIntegrantTypeStore['isFacetsDisabled'] =
    state.integrantType.facets.filter(i => isEmptyString(i.description) || isEmptyString(i.title)).length > 0;

  const isVideoDisabled: ICreateIntegrantTypeStore['isVideoDisabled'] =
    (Boolean(state.integrantType.bunny_video_title) &&
      !state.bunny_video_file &&
      !Boolean(state.integrantType.bunny_video_url)) ||
    (Boolean(state.integrantType.bunny_video_url) && !state.integrantType.bunny_video_title) ||
    (Boolean(state.integrantType.youtube_video_url) && validURL(state.integrantType.youtube_video_url));

  const isIngredientsDisabled: ICreateIntegrantTypeStore['isIngredientsDisabled'] =
    state?.ingredients?.filter(
      (item: any) =>
        item.currentFile === null ||
        isEmptyString(item.description) ||
        isEmptyString(item.title) ||
        isEmptyString(item.directions) ||
        isEmptyString(item.product_type) ||
        typeof item.product_type !== 'string' ||
        (item?.external_id && !item.isUniqueBatchId)
    ).length > 0;

  useEffect(() => {
    switch (true) {
      case isDetailsDisabled:
        setSaveButtonMessage('You Need Title, Description, Directions, Sales Channel and Product Image Filled Out.');
        break;
      case isFacetsDisabled:
        setSaveButtonMessage(`You Need ${titles.facetsPlural} Filled Out.`);
        break;
      case isIngredientsDisabled:
        setSaveButtonMessage(
          `You Need Title, Description, Directions, Sales Channel and Product Image Filled Out and Static Batch No must be unique if exist Inside Product Ingredients.`
        );
        break;
      case isVideoDisabled:
        if (Boolean(state.integrantType.youtube_video_url) && validURL(state.integrantType.youtube_video_url))
          setSaveButtonMessage('Please enter valid video url.');
        else if (Boolean(state.integrantType.bunny_video_url) && !state.integrantType.bunny_video_title)
          setSaveButtonMessage('Please upload video title.');
        else setSaveButtonMessage('Please upload video file.');
        break;
      default:
        setSaveButtonMessage('Save');
        break;
    }
  }, [isDetailsDisabled, isFacetsDisabled, isVideoDisabled, isIngredientsDisabled]);

  const onClearData: ICreateIntegrantTypeStore['onClearData'] = async () => {
    setState({
      ...state,
      slideIndex: 0,
      currentFile: null,
      bunny_video_file: null,
      facets: [],
      isLoading: false,
      isOpen: false,
      integrantType: {
        ...state.integrantType,
        title: '',
        description: '',
        external_id: '',
        warnings: '',
        directions: '',
        facets: [],
        image_thumb_url: '',
        integrant_type_id: '',
        product_type: '',
        youtube_video_url: '',
        bunny_video_title: '',
        bunny_video_url: '',
        bunny_video_thumbnail_url: ''
      },
      old_external_id: '',
      openSurveyQuestion: false,
      ingredients: [],
      createOrUpdateIntegrantTypeData: {
        status: '',
        loading: false,
        message: '',
        data: null
      }
    });
  };

  const onRemoveFacet: ICreateIntegrantTypeStore['onRemoveFacet'] = async (id: string, ingredient: any = null) => {
    if (ingredient) {
      setState((pre: any) => ({
        ...pre,
        ingredients: pre?.ingredients?.map((item: any) =>
          item?.id === ingredient?.id
            ? {
                ...item,
                facets: item?.facets?.filter((facet: IFacetDTO) => facet.id !== id)
              }
            : item
        )
      }));
    } else {
      setState({
        ...state,
        facets: state.facets.filter((facet: IFacetDTO) => facet.id !== id)
      });
    }
  };

  const updateFacets = (facets: IFacetDTO[], facet: IFacetDTO, key: keyof IFacetDTO): IFacetDTO[] =>
    facets.map(f => (f[key] === facet[key] ? facet : f));

  const onAddFacet: ICreateIntegrantTypeStore['onAddFacet'] = async (facet: IFacetDTO, ingredient: any = null) => {
    if (isEmptyString(facet.id)) {
      if (ingredient) {
        setState((pre: any) => ({
          ...pre,
          ingredients: pre?.ingredients?.map((item: any) =>
            item?.id === ingredient?.id
              ? {
                  ...item,
                  facets: [...item?.facets, { ...facet, id: generateClientID() }]
                }
              : item
          )
        }));
      } else {
        setState({
          ...state,
          facets: [...facets, { ...facet, id: generateClientID() }]
        });
      }
    } else {
      if (ingredient) {
        setState((pre: any) => ({
          ...pre,
          ingredients: pre?.ingredients?.map((item: any) =>
            item?.id === ingredient?.id
              ? {
                  ...item,
                  facets: item?.facets?.map((f: IFacetDTO) => (f.id === facet.id ? facet : f))
                }
              : item
          )
        }));
      } else {
        setState({ ...state, facets: updateFacets(facets, facet, 'id') });
      }
    }
  };

  const onUpdateDetails: ICreateIntegrantTypeStore['onUpdateDetails'] = (key, value, ingredient: any = null) => {
    if (ingredient) {
      setState((pre: any) => ({
        ...pre,
        ingredients: pre?.ingredients?.map((item: any) =>
          item?.id === ingredient?.id
            ? {
                ...item,
                [key]: value
              }
            : item
        )
      }));
    } else {
      setState((pre: any) => ({
        ...pre,
        integrantType: {
          ...pre.integrantType,
          [key]: value
        }
      }));
    }
  };

  const onProductUpdateStoreInitialData: ICreateIntegrantTypeStore['onProductUpdateStoreInitialData'] = data => {
    setState({
      ...state,
      integrantType: {
        ...state.integrantType,
        title: data?.title,
        description: data?.description,
        external_id: data?.external_id,
        directions: data?.directions,
        warnings: data?.warnings,
        product_type: typeof data?.product_type === 'string' ? data?.product_type : '',
        youtube_video_url: data?.youtube_video_url || '',
        bunny_video_title: data?.bunny_video_title || '',
        bunny_video_url: data?.bunny_video_url || '',
        bunny_video_thumbnail_url: data?.bunny_video_thumbnail_url || ''
      },
      facets: data?.facets,
      currentFile: data?.image_url,
      old_external_id: data?.external_id
    });
  };

  const gotoSlideIndex: ICreateIntegrantTypeStore['gotoSlideIndex'] = async (
    i: number,
    isUpdate,
    ingredient: any = null
  ) => {
    if (ingredient) {
      if (ingredient?.slideIndex === 0 && ingredient?.external_id) {
        const checkCurrentBatchData: any = await checkCurrentBatch(
          { ...currentIntegrant, organization_id: selectedOrganization.id },
          ingredient.external_id
        );
        if (checkCurrentBatchData) {
          isOpenToast('isError', `${ingredient?.title} Static Batch Number Already Exists!`);
        } else {
          setState((pre: any) => ({
            ...pre,
            ingredients: pre?.ingredients?.map((item: any) =>
              item?.id === ingredient?.id ? { ...item, slideIndex: i, isSubmitForm: true } : item
            )
          }));
        }
      } else {
        setState((pre: any) => ({
          ...pre,
          ingredients: pre?.ingredients?.map((item: any) =>
            item?.id === ingredient?.id ? { ...item, slideIndex: i, isSubmitForm: true } : item
          )
        }));
      }
    } else {
      if (
        state.slideIndex == 0 &&
        integrantType?.external_id &&
        (isUpdate === false || (isUpdate && old_external_id !== integrantType?.external_id))
      ) {
        const checkCurrentBatchData: any = await checkCurrentBatch(
          { ...currentIntegrant, organization_id: selectedOrganization.id },
          integrantType.external_id
        );
        if (checkCurrentBatchData) {
          isOpenToast('isError', 'Static Batch Number Already Exists!');
        } else {
          setState({
            ...state,
            slideIndex: i,
            isSubmitForm: true
          });
        }
      } else {
        setState({
          ...state,
          slideIndex: i,
          isSubmitForm: true
        });
      }
    }
  };

  const onSave: ICreateIntegrantTypeStore['onSave'] = async (file, integrantType, isUpdate) => {
    setState({
      ...state,
      isLoading: true
    });
    const postIntegrantType = await post(file, { ...integrantType, facets: convertToCreateFacets(facets) }, isUpdate);
    setState({
      ...state,
      createOrUpdateIntegrantTypeData: { ...postIntegrantType, isUpdate }
    });
    // if (typeof postIntegrantType !== 'undefined' && !postIntegrantType.hasOwnProperty('statusCode')) {
    //   await initIntegrantType();
    //   onClearData();
    // }
    return postIntegrantType;
  };

  const onNIHLabelSave: ICreateIntegrantTypeStore['onNIHLabelSave'] = async () => {
    setState((pre: any) => ({ ...pre, isLoading: true }));
    const { title, description, directions, warnings, facets, external_id, product_type } = state.integrantType;
    let formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (Boolean(state.currentFile)) {
      if (typeof state.currentFile !== 'string') {
        formData.append('productImage', currentFile || '');
        formData.append('image_url', '');
      } else {
        formData.append('image_url', currentFile || '');
        formData.append('productImage', '');
      }
    }
    formData.append('directions', directions);
    formData.append('warnings', warnings);
    formData.append('facets', JSON.stringify(facets));
    formData.append('external_id', external_id);
    formData.append('product_type', product_type);
    formData.append(
      'ingredients',
      JSON.stringify(
        ingredients?.map((item: any) => {
          return {
            title: item?.title,
            description: item?.description,
            directions: item?.directions,
            warnings: item?.warnings,
            facets: JSON.stringify(item?.facets),
            external_id: item?.external_id,
            product_type: item?.product_type,
            image_url: Boolean(item?.currentFile) && typeof item?.currentFile !== 'object' ? item?.currentFile : ''
          };
        })
      )
    );
    ingredients?.forEach(
      (ingredient: any) =>
        typeof ingredient.currentFile === 'object' && formData.append('ingredientImages', ingredient.currentFile)
    );
    if (!ingredients?.length) {
      formData.append('ingredientImages', '');
    }
    const response = await onSaveNIHProductAndIngredients(formData);
    if (response?.status === '1') {
      isOpenToast('success', response.message);
      onClearData();
    } else {
      setState((pre: any) => ({
        ...pre,
        isLoading: false
      }));
      if (response?.message) {
        isOpenToast('isError', response?.message || 'Something went wrong! Please try after sometime.');
      }
      if (!response?.data?.is_valid_domain_name) {
        navigate('/organization');
      }
    }
  };

  const setFile = (file: File | null | string, ingredient: any = null): void => {
    if (ingredient) {
      setState((pre: any) => ({
        ...pre,
        ingredients: pre?.ingredients?.map((item: any) =>
          item?.id === ingredient?.id ? { ...item, currentFile: file } : item
        )
      }));
    } else {
      setState({
        ...state,
        currentFile: file
      });
    }
  };

  const setBunnyVideoFile = (file: File | null, ingredient: any = null): void => {
    if (ingredient) {
      setState((pre: any) => ({
        ...pre,
        ingredients: pre?.ingredients?.map((item: any) =>
          item?.id === ingredient?.id ? { ...item, bunny_video_file: file } : item
        )
      }));
    } else {
      setState({
        ...state,
        bunny_video_file: file
      });
    }
  };

  const onRemoveBunnyVideo = (): void => {
    setState(pre => ({
      ...pre,
      isDeleteBunnyVideo: true,
      integrantType: {
        ...pre?.integrantType,
        bunny_video_title: '',
        bunny_video_url: '',
        bunny_video_thumbnail_url: ''
      }
    }));
  };

  const setOpen = (isOpen: boolean): void => {
    setState({
      ...state,
      isOpen: isOpen
    });
  };

  const setOpenSurveyQuestion = (openSurveyQuestion: boolean): void => {
    setState({
      ...state,
      openSurveyQuestion: openSurveyQuestion
    });
  };

  return {
    facets,
    old_external_id,
    currentFile,
    saveButtonMessage,
    isDetailsDisabled,
    isFacetsDisabled,
    integrantType,
    isLoading,
    isOpen,
    slideIndex,
    ingredients,
    isIngredientsDisabled,
    isSubmitForm,
    bunny_video_file,
    createOrUpdateIntegrantTypeData,
    isVideoDisabled,
    isDeleteBunnyVideo,
    onSave,
    onAddFacet,
    onUpdateDetails,
    gotoSlideIndex,
    onRemoveFacet,
    setFile,
    setOpen,
    onClearData,
    onProductUpdateStoreInitialData,
    setOpenSurveyQuestion,
    openSurveyQuestion,
    updateIngredients,
    addIngredients,
    onNIHLabelSave,
    verifyIngredientStaticBatchId,
    onRemoveIngredients,
    setBunnyVideoFile,
    onRemoveBunnyVideo
  };
};

export { useCreateIntegrantTypeStore };
