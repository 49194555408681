import React, { useState } from 'react';
import { ICreateIntegrantTypeDTO } from '../../lib/integrantType';
import { IFacetDTO } from '../../lib/facet';
import { create_integrant_type_ } from '../common/models';
import { CreateContext } from '../types/common';
import { useOrganizationStore } from '../hooks/useOrganizationStore';
import { IAPIResponseDTO } from '../../lib/common';

export interface ICreateIntegrantTypeState {
  slideIndex: number;
  isLoading: boolean;
  isOpen: boolean;
  integrantType: ICreateIntegrantTypeDTO;
  currentFile: File | null | string;
  facets: IFacetDTO[];
  old_external_id: string;
  openSurveyQuestion: boolean;
  ingredients: any;
  isSubmitForm: boolean;
  bunny_video_file: File | null;
  createOrUpdateIntegrantTypeData: any;
  isDeleteBunnyVideo: boolean;
}

const defaults = {
  integrantType: create_integrant_type_,
  isOpen: false,
  isLoading: false,
  slideIndex: 0,
  currentFile: null,
  facets: [],
  old_external_id: '',
  openSurveyQuestion: false,
  ingredients: [],
  isSubmitForm: false,
  bunny_video_file: null,
  createOrUpdateIntegrantTypeData: null,
  isDeleteBunnyVideo: false
};

const CreateIntegrantTypeContext = React.createContext<CreateContext<ICreateIntegrantTypeState>>([
  defaults,
  (): ICreateIntegrantTypeState => defaults
]);

const CreateIntegrantTypeProvider = (props: { children?: JSX.Element }): JSX.Element => {
  const { selectedOrganization } = useOrganizationStore();
  const { account_id, id } = selectedOrganization;
  const [state, setState] = useState<ICreateIntegrantTypeState>({
    ...defaults,
    integrantType: { ...defaults.integrantType, organization_id: id, created_by: account_id, updated_by: account_id }
  });
  return (
    <CreateIntegrantTypeContext.Provider value={[state, setState]}>
      {props.children}
    </CreateIntegrantTypeContext.Provider>
  );
};

export { CreateIntegrantTypeContext, CreateIntegrantTypeProvider };
