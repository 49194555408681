import React, { FunctionComponent, CSSProperties, useEffect } from 'react';
import { Layer } from '../.././common/Layer';
import { CreateViewBatchesSlide } from '../../../src/route/dashboard/CreateViewBatchesSlide';
import { CreateIntegrantTypeSlide } from '../../../src/route/dashboard/CreateIntegrantTypeSlide';
import { useRequestIntegrantTypes } from '../../../src/hooks/useRequestIntegrantTypes';
import { SelectIntegrantTypeSlide } from './SelectIntegrantTypeSlide';
import '../../styles/home.css';
import { CreateSurveyQuestion } from '../../../src/route/dashboard/CreateSurveyQuestion';
import PublisherDashboard from './Publisher';
import VerifierDashboard from './Verifier';
import { useIndividualSubscriptionDetailStore } from 'hooks/useIndividualSubscriptionDetailStore';
import { useAuthStore } from 'hooks/useAuthStore';
import DomainVerification from './DomainVerification';
import OrganizationVerification from './OrganizationVerification';

const dashboardWrapperStyle: CSSProperties = { position: 'relative', overflow: 'hidden' };

const DashboardView: FunctionComponent = () => {
  useRequestIntegrantTypes();
  const {
    selectedSubscription,
    individual: { is_valid_domain_name, is_approved }
  } = useAuthStore();
  const { initGetDashboardOverview } = useIndividualSubscriptionDetailStore();

  useEffect(() => {
    if (selectedSubscription === 'verifier' || selectedSubscription === 'publisher') {
      initGetDashboardOverview({ subscription_type: localStorage.getItem('selectedSubscription') });
    }
  }, [selectedSubscription]);

  return (
    <Layer fill style={dashboardWrapperStyle} direction="column" width={'100%'}>
      {is_valid_domain_name ? (
        is_approved ? (
        <>
          {selectedSubscription === 'tracer' && (
            <>
              <SelectIntegrantTypeSlide />
              <CreateViewBatchesSlide />
              <CreateIntegrantTypeSlide />
              <CreateSurveyQuestion />
            </>
          )}
          {selectedSubscription === 'verifier' && <VerifierDashboard />}
          {selectedSubscription === 'publisher' && <PublisherDashboard />}
        </>) :(
          <>
          <OrganizationVerification/>
          </>
        )
      ) : (
        <>
          <DomainVerification />
        </>
      )}
    </Layer>
  );
};

export { DashboardView };
